body {
  margin: 0;
  font-family: -apple-system, "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

span {
  display: none;
}

.tickBoxes {
  justify-content: center !important;
}

/* REACT-SELECT STYLES -- OVERWRITES */
.sc-bTvSiT {
  width: 175px!important;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  border-radius: 10px !important;
  width: 175px!important;
}
.css-2b097c-container {
  width: 75px !important;
}

.css-26l3qy-menu {
  width: 175px!important;
}

.css-1wa3eu0-placeholder {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  /* padding: 4px 10px; */
  color: rgb(128, 128, 128)!important;
  font-size: 14px!important;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.scrollableShadows {
  /* shadow effects for scrolling are below */
  background-image: linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(179, 179, 179, 0.4), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(179, 179, 179, 0.4), rgba(255, 255, 255, 0));
  /* Shadows */
  /* Shadow covers */
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 30px 100%, 30px 100%, 20px 100%, 20px 100%;
  background-attachment: local, local, scroll, scroll;
}

/* common style class can be taken from here */


.blueButton{
  height: 25px;
  width: auto;
  color: white;
  background-color: #1572de;
  border-radius: 2.5px;
  font-size: 13px;
  outline: none;
  border: none;
  cursor: pointer;
  width: max-content;
}

.blueButton:hover{
  border: 1px solid #d6d6d6;
}

